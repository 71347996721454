import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@material-ui/core/Dialog";

const contents = [
  { label: "社名", content: "株式会社クレステージ" },
  { label: "代表者", content: "代表取締役 新名 健二" },
  {
    label: "本社",
    content: `〒150-0045<br/>東京都渋谷区神泉町22－6 渋谷MKビル4階`,
  },
  { label: "オフィシャルHP", content: "https://crestage.jp" },
  { label: "設立", content: "平成27年5月12日" },
  { label: "資本金", content: "9,000,000円" },
  {
    label: "業務内容",
    content: `1. 不動産の賃貸、仲介、管理及び売買<br/>2. 不動産に関するコンサルティング業務<br/>3. 建築、建設業及びリフォーム業<br/>4. 生命保険及び損害保険代理業<br/>5. 上記各号に附帯する一切の業務`,
  },
  { label: "免許番号", content: "免許番号	東京都知事（2）第97985号" },
  {
    label: "加盟団体",
    content:
      "社団法人全国宅地建物取引業保証協会会員<br />社団法人東京都宅地建物取引業協会会員",
  },
  { label: "取引金融機関", content: "三菱東京UFJ銀行" },
];

const Company = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: 1280 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">会社概要</TableCell>
              <TableCell align="left">
                <IconButton
                  aria-label="close"
                  onClick={props.onClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contents.map((row) => (
              <TableRow
                key={row.label}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" style={{fontSize:"12px"}}>
                  {row.label}
                </TableCell>
                <TableCell align="left" style={{fontSize:"12px"}}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: row.content,
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
};
export default Company;
