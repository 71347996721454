import React from "react";
import Suumo from "./pages/suumo";
import Office from "./pages/office";

import Footer from "./pages/footer";
import { BrowserRouter, Route } from "react-router-dom";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Route exact path="/">
          <Suumo />
        </Route>
        <Route path="/office">
          <Office />
        </Route>
      </BrowserRouter>
      <Footer />
    </>
  );
};

export default App;
