import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

const Help = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Paper style={{textAlign:"center"}}>
        <div style={{margin: "20px 40px 30px 40px"}}>{"ページは準備中です。"}</div>
      <DialogActions>
        <Button onClick={props.onClose}>閉じる</Button>
      </DialogActions>
      </Paper>
    </Dialog>
  );
};
export default Help;