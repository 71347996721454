import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@mui/material/Hidden";
import Company from "./company";
import Privacy from "./privacy";
import About from "./about";
import Help from "./help";

const Footer = () => {
  const [about, setAbout] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [company, setCompany] = useState(false);
  const [help, setHelp] = useState(false);

  const pages = [
    { label: "About", link: "", action: "handleAboutOpen" },
    { label: "プライバシーポリシー", link: "", action: "handlePrivacyOpen" },
    { label: "運営会社情報", link: "", action: "handleCompanyOpen" },
    { label: "ヘルプ", link: "", action: "handleHelpOpen" },
  ];

  const handleAboutOpen = (e) => {
    e.preventDefault();
    setAbout(true);
  };
  const handlePrivacyOpen = (e) => {
    e.preventDefault();
    setPrivacy(true);
  };
  const handleCompanyOpen = (e) => {
    e.preventDefault();
    setCompany(true);
  };
  const handleHelpOpen = (e) => {
    e.preventDefault();
    setHelp(true);
  };

  const handleAboutClose = (e) => {
    e.preventDefault();
    setAbout(false);
  };
  const handlePrivacyClose = (e) => {
    e.preventDefault();
    setPrivacy(false);
  };
  const handleCompanyClose = (e) => {
    e.preventDefault();
    setCompany(false);
  };
  const handleHelpClose = (e) => {
    e.preventDefault();
    setHelp(false);
  };

  return (
    <div style={{ backgroundColor: "#303030", marginTop: "30px" }}>
      <div style={{ padding: "30px 0" }}>
        <Hidden smDown>
          <Grid container justifyContent="center" spacing={5}>
            {pages.map((page) => (
              <Grid item ms={5}>
                <Button
                  onClick={eval(page.action)}
                  style={{ color: "#FFFFFF" }}
                >
                  {page.label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Hidden>
        <Hidden smUp>
          {pages.map((page) => (
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <Button onClick={eval(page.action)} style={{ color: "#FFFFFF" }}>
                {page.label}
              </Button>
            </div>
          ))}
        </Hidden>
      </div>
      <div style={{ borderTop: "1px solid #cccccc" }} />
      <div
        style={{
          textAlign: "center",
          color: "#fff",
          padding: "10px 0",
          fontSize: "8px",
        }}
      >
        Copyright CheckFee All rights reserved.
      </div>
      <About open={about} onClose={handleAboutClose} />
      <Company open={company} onClose={handleCompanyClose} />
      <Privacy open={privacy} onClose={handlePrivacyClose} />
      <Help open={help} onClose={handleHelpClose} />
    </div>
  );
};
export default Footer;
