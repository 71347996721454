import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import CardMedia from "@mui/material/CardMedia";
import Logo from "../img/logo.png";
import { makeStyles } from "@material-ui/core/styles";
import Help from "./help";

const useStyles = makeStyles({
  pcLogo: {
    maxWidth: "150px",
  },
  spLogo: {
    maxWidth: "150px",
  },
});
const pages = [
  { label: "利用方法", action: "handleHowOpen" },
  { label: "ヘルプ", action: "handleHelpOpen" },
  {
    label: "事例紹介",
    action: "handleArchivesOpen",
  }
];
const settings = ["ログイン機能準備中"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [how, setHow] = useState(false);
  const [help, setHelp] = useState(false);
  const [archives, setArchives] = useState(false);

  const classes = useStyles();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleHowOpen = (e) => {
    e.preventDefault();
    console.log("run");
    setHow(true);
  };
  const handleHelpOpen = (e) => {
    e.preventDefault();
    setHelp(true);
  };
  const handleArchivesOpen = (e) => {
    e.preventDefault();
    setArchives(true);
  };

  const handleHowClose = (e) => {
    e.preventDefault();
    setHow(false);
  };
  const handleHelpClose = (e) => {
    e.preventDefault();
    setHelp(false);
  };
  const handleArchivesClose = (e) => {
    e.preventDefault();
    setArchives(false);
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            >
              <CardMedia
                className={classes.pcLogo}
                component="img"
                image={Logo}
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.label} onClick={eval(page.action)}>
                    {page.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              <CardMedia
                className={classes.spLogo}
                component="img"
                image={Logo}
              />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.label}
                  onClick={eval(page.action)}
                  sx={{ my: 2, color: "black", display: "block" }}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="ログイン機能">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="check fee" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Help open={how} onClose={handleHowClose} />
      <Help open={help} onClose={handleHelpClose} />
      <Help open={archives} onClose={handleArchivesClose} />
    </>
  );
};
export default ResponsiveAppBar;
