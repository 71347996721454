import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Rating from "@mui/material/Rating";
import React, { useState } from "react";
import Elite from "../img/elite.png";
import Line from "../img/line.png";
import Operator from "../img/operator.png";
import Reserch from "../img/reserch.png";
import Secret from "../img/secret.png";
import Shop from "../img/shop.png";
import Hidden from "@mui/material/Hidden";
import Header from "../pages/header";
import Countup from "react-countup";

const cheerio = require("cheerio");

const styles = makeStyles((theme) => ({
  rootBtn: {
    color: "#ffffff",
    backgroundColor: "#5f9ea0",
    "&:disabled": {
      color: "#ffffff",
      backgroundColor: "#a0b7b8",
    },
  },
  icons: {},
}));

const Suumo = () => {
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstOpen, setFirstOpen] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const [thirdOpen, setThirdOpen] = useState(false);
  const [loading, setLoading] = useState(0);
  const [suumo, setSuumo] = useState({
    title: "",
    address: "",
    scale: "",
    price: "",
    thumbnail: "",
  });
  const classes = styles();
  const cheers = [
    {
      icon: Reserch,
      highlight: "徹底リサーチ",
      description:
        "都内の物件であればどこでもご案内可能です。スーモに掲載されている物件以外でも調査可能です。",
    },
    {
      icon: Secret,
      highlight: "個人情報の保護",
      description:
        "本サービスを利用していることは第三者にバレる心配はございまん。また違法性もありません。",
    },
    {
      icon: Shop,
      highlight: "事務所あり",
      description: `本サービスの運営は不動産会社が直接行っておりますので、ご来社いただきましてのご相談も可能です。`,
    },
    {
      icon: Elite,
      highlight: "審査に強い",
      description:
        "審査が不安な方、特殊な事情やご職業の方、また別の不動産でお断りされた方でも審査を頑張らせていただきます。",
    },
    {
      icon: Line,
      highlight: "LINEにての相談",
      description: `初回のお問い合わせ後にLINE登録をしていただければLINEでのご対応を致します。`,
    },
    {
      icon: Operator,
      highlight: "サポート窓口あり",
      description: `お困りのことが有りましたらサポートさせていただきます。<br />※現在はLINEのみ受付中です。`,
    },
  ];

  /* 初期画面のコンポーネント */
  const handleInputChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };
  const handlePaste = async (event) => {
    event.preventDefault();
    try {
      const text = await navigator.clipboard.readText();
      setUrl(text);
    } catch (err) {
      console.log(err);
    }
  };
  /** ここまで **/

  /* 個人情報フォーム */
  const handleInputChangeName = (event) => {
    event.preventDefault();
    setName(event.target.value);
  };
  const handleInputChangeEmail = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };
  const handleInputChangePhone = (event) => {
    event.preventDefault();
    setPhone(event.target.value);
  };
  /** ここまで **/

  /* サブミット系 */
  const handleSurvey = (event) => {
    event.preventDefault();
    if (!url) {
      return;
    }
    setFirstOpen(true);
    get(
      "https://t9qut9rg3h.execute-api.us-east-1.amazonaws.com/dev?url=" + url
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setSecondOpen(true);
  };
  const handleSlack = (event) => {
    event.preventDefault();
    const requestOptions = {
      method: "GET",
    };
    fetch(
      `https://35tnv0uk33.execute-api.us-east-1.amazonaws.com/dev/message?url=${url}&name=${name}&phone=${phone}&email=${email}`,
      requestOptions
    );
    setFirstOpen(false);
    setSecondOpen(false);
    setThirdOpen(true);
    setUrl("");
    setName("");
    setEmail("");
    setPhone("");
  };
  /** ここまで **/

  /* ダイアログを閉じる奴 */
  const handleFirstClose = (event) => {
    event.preventDefault();
    setFirstOpen(false);
  };
  const handleSecondClose = (event) => {
    event.preventDefault();
    setSecondOpen(false);
  };
  const handleThirdClose = (event) => {
    event.preventDefault();
    setThirdOpen(false);
  };
  /** ここまで **/

  

  async function get(url) {
    try {
      setLoading(2);
      const res = await axios.get(url);
      const $ = cheerio.load(res.data);
      const title = $(
        "#wrapper > div.section_h1 > div.section_h1-header > h1"
      ).text();
      const price = $(
        "#js-view_gallery > div.property_view_note > div > div:nth-child(1) > span.property_view_note-emphasis"
      ).text();
      const address = $(
        "#js-view_gallery > div.l-property_view_table > table > tbody > tr:nth-child(1) > td"
      ).text();
      const scale = $(
        "#js-view_gallery > div.l-property_view_table > table > tbody > tr:nth-child(3) > td:nth-child(2)"
      ).text();

      const thumbnail = $(
        "#js-view_gallery-list > li:nth-child(1) > a > img"
      ).attr("data-src");
      console.log(thumbnail);

      setSuumo({
        title: title,
        scale: scale,
        price: price,
        address: address,
        thumbnail: thumbnail,
      });
      setLoading(1);
    } catch (error) {
      setSuumo("");
      get(url);
    }
  }

  return (
    <div>
      <Header />
      <div>
        <form>
          <Grid container>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={8}>
                <div style={{ maxWidth: "1280px", margin: "0 auto" }}>
                  <div
                    style={{
                      marginTop: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <h1 style={{ fontSize: "25px" }}> 仲介手数料減額診断 </h1>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "10px",
                      marginBottom: "50px",
                    }}
                  >
                    <h2 style={{ color: "#696969" }}>
                      ネット上に掲載されている物件の仲介手数料を減額できるか調査致します。
                    </h2>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <div
                    style={{
                      backgroundColor: "#666666",
                      width: "100%",
                      color: "#FFF",
                      textAlign: "center",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    <div style={{ maxWidth: "1280px", margin: "0 auto" }}>
                      <div style={{ margin: "30px 0px", fontSize: "13px" }}>
                        合計 <Countup style={{fontSize:"20px"}} end={20803179} duration={2} separator={","}/> 円 の仲介手数料が減額されました
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#999999",
                      width: "100%",
                      color: "#FFF",
                    }}
                  >
                    <div style={{ maxWidth: "1280px", margin: "0 auto" }}>
                      <div
                        style={{
                          marginTop: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "10px",
                        }}
                      >
                        気になる物件のURLをコピー・ペーストしてください。
                      </div>
                      <div
                        style={{
                          marginBottom: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={10} sm={8}>
                            <TextField
                              style={{
                                backgroundColor: "white",
                              }}
                              name="URL"
                              value={url}
                              onChange={handleInputChange}
                              fullWidth
                              placeholder="  例) https://suumo.jp/chintai/jnc_000070687430/"
                              autoComplete="off"
                            />
                            <Grid
                              container
                              justifyContent="flex-end"
                              style={{ marginTop: "10px" }}
                            >
                              <Button
                                style={{
                                  color: "#FFF",
                                  backgroundColor: "#524e4d",
                                  marginRight: "5px",
                                }}
                                color="default"
                                variant="contained"
                                onClick={handlePaste}
                              >
                                ペースト
                              </Button>
                              <Button
                                className={classes.rootBtn}
                                variant="contained"
                                color="primary"
                                onClick={handleSurvey}
                                disabled={
                                  !url.startsWith("https://suumo.jp/chintai/")
                                }
                              >
                                {"　診断　"}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <div
        style={{ marginTop: "100px", display: "grid", gridAutoFlow: "column" }}
      >
        <div style={{ maxWidth: "1280px", margin: "0 auto" }}>
          <Grid container justifyContent="center">
            <Grid
              container
              md={8}
              sm={10}
              xs={12}
              spacing={3}
              justifyContent="center"
            >
              {cheers.map((cheer) => (
                <Grid item md={4} sm={5} xs={10}>
                  <Box>
                    <CardMedia
                      component="img"
                      style={{
                        objectFit: "cover",
                        width: "35px",
                        margin: "0 auto",
                        padding: "20px 0",
                      }}
                      image={cheer.icon}
                      alt={cheer.highlight}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        style={{ fontSize: "16px" }}
                        component="div"
                        textAlign="center"
                      >
                        {cheer.highlight}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <div
                          style={{ fontSize: "12px" }}
                          dangerouslySetInnerHTML={{
                            __html: cheer.description,
                          }}
                        />
                      </Typography>
                    </CardContent>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ margin: "50px 0", borderTop: "1px solid #c0c0c0" }} />
      <Hidden smDown>
        <Grid container justifyContent="center">
          <Grid item>{"お客様満足度調査結果　"}</Grid>
          <Grid item>
            <Rating name="read-only" value={4.3} readOnly />
          </Grid>
          <Grid item>{"　4.2 (103票)"}</Grid>
        </Grid>
        <div
          style={{
            textAlign: "center",
            color: "#a9a9a9",
            fontSize: "10px",
            marginTop: "10px",
            marginBottom: "50px",
          }}
        >
          契約完了後、かんたんなフィードバックを
          お願いしておりますのでご協力お願い致します。
        </div>
      </Hidden>
      <Hidden smUp>
        <Grid container justifyContent="center" textAlign="center">
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>{"お客様満足度調査結果"}</div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Rating name="read-only" value={4.3} readOnly />
          </Grid>
          <Grid textAlign="center" item xs={12}>
            <div style={{ textAlign: "center" }}>{"4.2 (103票)"}</div>
          </Grid>
        </Grid>
        <div
          style={{
            textAlign: "center",
            color: "#a9a9a9",
            fontSize: "10px",
            marginTop: "20px",
            marginBottom: "50px",
          }}
        >
          契約完了後、かんたんなフィードバックを
          <br />
          お願いしておりますのでご協力お願い致します。
        </div>
      </Hidden>
      <Dialog
        open={firstOpen}
        onClose={handleFirstClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        {loading === 2 ? (
          <Card sx={{ width: 345, height: 434 }}>
            <CircularProgress />
          </Card>
        ) : null}
        {loading === 1 ? (
          <>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  style={{
                    objectFit: "cover",
                    height: "256px",
                    width: "345px",
                  }}
                  image={suumo.thumbnail}
                  alt={suumo.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {suumo.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <div>{suumo.address}</div>
                    <div>{suumo.price}</div>
                    <div>{suumo.scale}</div>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card style={{ marginTop: "10px" }}>
              <CardContent>
                <Typography>
                  <div style={{ margin: "10px 0" }}>
                    この物件でお間違い無いですか？
                  </div>
                </Typography>
                <DialogActions>
                  <Button
                    size="small"
                    onClick={handleFirstClose}
                    color="secondary"
                  >
                    いいえ
                  </Button>
                  <Button size="small" onClick={handleSubmit} color="primary">
                    はい
                  </Button>
                </DialogActions>
              </CardContent>
            </Card>
          </>
        ) : null}
      </Dialog>
      <Dialog open={secondOpen} onClose={handleSecondClose}>
        <DialogTitle>情報入力</DialogTitle>
        <DialogContent>
          <DialogContentText>
            診断結果を受け取るためにお名前とメールアドレスを入力してください。
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            value={name}
            onChange={handleInputChangeName}
            label="お名前"
            type="name"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="phone"
            value={phone}
            onChange={handleInputChangePhone}
            label="電話番号"
            type="phone"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="name"
            value={email}
            onChange={handleInputChangeEmail}
            label="メールアドレス"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSecondClose}>キャンセル</Button>
          <Button onClick={handleSlack}>送信する</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={thirdOpen} onClose={handleThirdClose}>
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                受付を完了致しました。
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ margin: "20px 0" }}
              >
                担当者より、ご連絡させていただきます。
                <br />
                今暫くお待ちいただくようお願い申し上げます。
              </Typography>
              <DialogActions>
                <Button size="small" onClick={handleThirdClose} color="primary">
                  閉じる
                </Button>
              </DialogActions>
            </CardContent>
          </CardActionArea>
        </Card>
      </Dialog>
    </div>
  );
};

export default Suumo;
