import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@material-ui/core/Button";
import Logo from "../img/logo.png";
import Paper from "@material-ui/core/Paper";

const About = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Paper style={{textAlign:"center"}}>
        <img
          src={Logo}
          style={{ maxWidth: "150px", margin: "20px 0 30px 0" }}
        />
        <div style={{ padding: "0 30px 20px 30px", textAlign: "center", fontSize:"10px"}}>
          Check feeは 不要な仲介業者をパスし、直接オーナーと契約することで
          <br />
          不要な仲介手数料を減額に出来るように手配する仕組みです。
          <br />
          <br />
          他の不動産業者で仲介手数料がかかると言われた場合でも
          <br />
          同じ物件で手数料を減額できる可能性が高いです。
          <br />
          <br />
          運営コストはオーナー様からの紹介料より捻出される為、
          <br />
          ご利用者様にはデメリットは一切御座いません。
        </div>
        <DialogActions>
          <Button onClick={props.onClose}>閉じる</Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};
export default About;
